.tippy-box[data-theme~='dropdown'], [data-theme~='dropdown'], .dropdown-theme {
  box-shadow: 0 0 5px #c8c8c8;
  background: white;

  .tippy-content {
    background: white;
    color: black;
    border-radius: 7px;
    margin: 0;
    padding: 7px;
    font-size: 13px;
    & > p, & > div {
      padding: 5px;
    }
  }
  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    li {
      position: relative;
      display: flex;
      align-items: center;
      .ajax-loader-small {
        display: inline-block;
        margin-left: 5px;
        top: 3px;
        position: relative;
      }
      a {
        padding: 7px 10px;
        color: var(--grey-text-color);
        display: inline-block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        font-weight: 400;
        &:not(.no-hover):focus, &:not(.no-hover):hover {
          text-decoration: none;
          color: var(--grey-text-color);
          cursor: pointer;
        }
        &.current {
          background: var(--primary-color);
          color: white;
          padding: 3px 10px;
          border-radius: var(--square-border-radius);
          &:hover {
            background: var(--primary-color);
            color: white;
          }
        }
      }
      &:not(.no-hover):hover {
        background: #f4f4f4;
        border-radius: var(--square-border-radius);
      }
    }
  }
}