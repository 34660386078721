.sm-modal[aria-hidden='true'] {
  display: none;
}

.sm-modal {
  display: block;
}

/**************************\
  Basic Modal Styles
\**************************/

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1102;
  min-height: 100vh;
}

.modal__content {
  min-width: 320px;
  min-height: 50px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}

.modal__container {
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;
  &.fullscreen {
    width: 95%;
  }
  a:focus {
    outline: none;
  }
}

.modal__header {
  text-align: right;
  background-color: #F6F6F6;
}

#modal__loader-wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	cursor: pointer;
	overflow: hidden;
	z-index: 1104;
  border-radius: 5px;
}

.modal__close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 26px;
  height: 25px;
  background: none;
  cursor: pointer;
  z-index: 1103;
  border: 0;
  font-size: 18px;
  line-height: 23px;
  border-radius: 5px;
  &:before { 
    content: "\2715";
  }
  &:hover {
    background: #f1eeee;
  }
  &:focus {
    outline: none;
  }
}

.sm-modal-content {
  padding: 35px 15px 15px;
}

.hidden {
  display: none;
}

@media (max-width: 420px) {
  .modal__overlay {
    padding: 5px;
  }
}