.switch-item {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  .switch-item-toggle {
    width: 35px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .switch-item-label {
    margin-left: 10px;
  }
}
