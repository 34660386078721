.new-contracts-form {
  max-width: 700px;

  .two-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 10px;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  .contact-info-date {
    line-height: 31px;
  }

  .canvas-wrapper, #contracts_form_contact_name {
    max-width: 400px;
  }

  .special-agreement-section {
    margin-top: 10px;
  }
}

#contract-modal .modal__overlay {
  z-index: 2000;
}

@media (max-width: 620px) {
  .new-contracts-form {
    .two-column-grid {
      grid-template-columns: 1fr;
    }

    .canvas-wrapper, #contracts_form_contact_name {
      max-width: 100%;
    }

    section {
      margin-bottom: 18px;
    }
  }
}