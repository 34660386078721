.ts-control {
  border: none;
  border-radius: 5px;
  background-color: whitesmoke;
  min-width: 270px;
  input, input[type="text"] {
    color: #222;
    font-size: 14px; 
    width: auto;
    min-width: 10px;
  }
  & > div {
    max-width: calc(100% - 23px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .item {
    min-height: 18px;
  }
}

.ts-wrapper.small-height, .ts-wrapper.single.input-active.small-height {
  .ts-control {
    padding-top: 4px;
    padding-bottom: 4px;
    background-position: calc(100% + 40px) 2px;
    input {
      height: 18px;
    }
  }
}

.single .ts-control {
  flex-wrap: nowrap;
}

.multi .ts-control {
  align-items: flex-end;
}

.ts-wrapper:not(.white-field).single.input-active .ts-control, .full.ts-wrapper:not(.white-field) .ts-control {
  background-color: whitesmoke;
}

.ts-control, .ts-wrapper.single.input-active .ts-control {
  background-image: url('../../images/select2x2.png');
  background-repeat: no-repeat;
  background-size: 60px 40px;
  background-position: calc(100% + 40px) 6px;
}

.disabled .ts-control {
  opacity: 1;
  background-color: whitesmoke;
}

.multi.ts-wrapper.small-height .ts-control {
  background-position: calc(100% + 40px) 2px!important;
  padding-top: 2px;
  padding-bottom: 0;
  input {
    height: 24px;
  }
  & > div {
    margin-bottom: 2px;
  }
}

.ts-wrapper.multi .ts-control > div.active {
  background: #c3c2c2;
}

.white-field .ts-control {
  background-color: white;
}

.ts-wrapper.multi, .ts-wrapper.multi.disabled {
  .ts-control > div {
    background: #e4e4e4;
    border-radius: 5px;
  }
}

.ts-wrapper.focus .ts-control {
  background-position: calc(100% + 40px) 6px;
}

.ts-wrapper .ts-control:not(.rtl) {
  padding-right: 18px!important;
}

.ts-dropdown {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  z-index: 200;
  text-align: left;
  .active, .option.selected.active {
    background-color: var(--primary-color);
    color: white;
  }

  .active.create {
    color: white;
    strong {
      font-size: 14px!important;
      font-weight: 500;
      color: white;
    }
  }

  .optgroup-header {
    font-weight: 600;
    padding: 6px;
  }

  .optgroup .option {
    padding-left: 15px;
  }

  .option.selected {
    background-color: #ececec;
  }
}

.ts-wrapper.with-error {
  border: 1px solid #b00000;
  border-radius: 5px;
}
