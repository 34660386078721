.ql-container {
  width: 100%;
  min-height: 100px;
  .ql-editor[contenteditable="false"] {
    opacity: 0.7;
  }

  strong {
    color: #333;
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 13px;
  }  

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.ql-snow.ql-toolbar {
  font-size: 13px;
  button {
    width: auto;
    &.ql-restoreDefaultTemplate:after {
      content: 'Restore Default Template';
    }
  }
  .ql-insert, .ql-placeholder {
    .ql-active, .ql-selected {
      color: #333;
    }
    .ql-stroke {
      stroke: #333!important;
    }
  }
  .ql-picker-label {
    padding-right: 20px;
    font-weight: 400;
  }
}

.ql-toolbar.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.inserted-placeholder, .full-width-placeholder {
  color: white;
  background-color: #6ea8dc;
  padding: 2px 3px;
  border-radius: 3px;
  white-space: nowrap;
}

.full-width-placeholder {
  padding-top: 0;
  padding-bottom: 0;
  display: block !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.lightgray-div {
  color: black;
  background-color: #e9e9e9;
  padding: 5px 3px 5px 3px;
  border: 1px solid #d3d3d3;
  display: block !important;
}



