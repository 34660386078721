.knowledge-base-articles-link {
  background-color: var(--light-primary-color);
  color: var(--btn-primary-bg);
  font-size: 12px;
  padding: 0 7px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 7px;
  position: relative;
  top: 2.5px;
  height: 19px;
}

.knowledgebase-links {
  margin-bottom: 40px;
  li {
    margin-bottom: 7px;
  }
}

.knowledgebase-links-popover {
  padding: 10px 20px;
  h3 {
    margin-bottom: 20px;
  }
  a:not(.btn) {
    color: var(--links-color);
    text-decoration: underline;
    &:hover, &:focus {
      color: var(--black-text-color);
    }
  }
  .modbold {
    font-size: 500;
  }
}

@media (max-width: 620px) {
  .knowledge-base-articles-link {
    top: 1px;
  }
}
