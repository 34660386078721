#schedule-showing-iframe-modal {
  position: relative;

  @media screen and (max-device-width: 767px) {
    min-height: 100%;
    width: 100%;
    border-radius: 0;
    border: none;
  }
  
  width: 600px;
  height: 100%;
  margin: 0;

  .iframe {
    border: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
}
