.sign-canvas {
  width: 100%;
  height: 200px;
  margin-top: 3px;
  background: whitesmoke;
  border-radius: 5px;
}

.canvas-wrapper {
  position: relative;
  .clear-btn {
    background: #e5e5e5;
    color: #737373;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    bottom: 15px;
    padding: 3px 5px;
    &:hover {
      background-color: #c3c3c3;
    }
  }
}
