@mixin select-field {
  appearance: none;
  background-image: url('../images/select2x2.png');
  background-repeat: no-repeat;
  background-size: 60px 40px;
  background-position: calc(100% + 40px) 5px;
  padding-right: 18px;
}

.messages-background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1100;
  background: rgba(146, 146, 146, 0.5);
  height: 100%;
}

.two-way-messaging-wrap {
  position: absolute;
  background: white;
  width: 550px;
  z-index: 10000;
  box-shadow: 0 0 5px rgb(0, 0, 0, 50%);
  border-radius: 5px;
  overflow: visible;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 60px;

  .messages {
    max-height: 350px;
    min-height: 85px;
    overflow-y: auto;
    padding: 10px 20px;
    margin-bottom: 0;
    position: relative;
  }

  .message-date {
    text-align: center;
    font-size: 13px;
    margin-bottom: 10px;
    margin-top: 25px;
    font-weight: 500;
    color: #8e8d8d;
  }

  .symbols-left {
    color: #818181;
    font-size: 13px;
    margin-bottom: 12px;
  }

  .message {
    font-size: 15px;
    margin-bottom: 15px;
    position: relative;

    .message-body {
      position: relative;
      padding: 8px 15px;
      color: white;
      background: var(--btn-primary-bg);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      cursor: default;
      &:hover {
        .sent-by {
          display: block;
        }
      }
    }

    &.incoming .message-body-wrap {
      display: flex;
      justify-content: flex-start;
      padding-right: 10%;
      padding-left: 0;
    }

    &.incoming .message-body {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 0;
      background: var(--body-color);
      color: var(--black-text-color);
    }
  }

  .mark-unread-wrap {
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .messages-header {
    .messages-header-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    select {
      width: 100%;
      border: none;
      background: var(--body-color);
      font-size: 14px;
      margin-bottom: 5px;
      height: 34px;
      border-radius: 5px;
      padding: 5px 10px;
      max-width: 100%;
      @include select-field;
    }

    padding-right: 40px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    box-shadow: 0 3px 8px -2px #cccccc;

    .lead-info {
      display: flex;
      align-items: baseline;
      gap: 7px;

      .phone {
        color: #5f5f5f;
        font-size: 17px;
      }

      .name {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  .toolbar {
    padding: 5px;
    box-shadow: 0 1px 6px 2px #cccccc;

    .submit-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 5px;
    }

    .chat-submit-buttons {
      display: flex;
    }

    select, .autoComplete_wrapper > input {
      width: 100%;
      border: none;
      background: var(--body-color);
      font-size: 14px;
      margin-bottom: 5px;
      height: 34px;
      border-radius: 5px;
      padding: 5px 10px;
    }

    select {
      @include select-field;
    }
  }

  textarea {
    width: 100%;
    height: 70px;
    background: var(--body-color);
    border: none;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    resize: vertical;
    max-height: 350px;
    &.with-disable-message {
      border-color: var(--alert-color);
    }
  }

  .disable-message {
    text-align: right;
    font-size: 13px;
    color: var(--alert-color);
  }

  .autocomplete-listing-summary {
    font-size: 13px;
    margin-bottom: 7px;
    color: #8e8d8d;
    margin-top: 2px;
  }

  .autocomplete-result-highlight {
    color: var(--text-color);
  }

  .autocomplete-results-list {
    border: none;
    box-shadow: 0 0 15px 0 #00000021;
    padding-top: 5px;
    padding-bottom: 5px;
    & > li {
      padding: 5px 12px;
      font-size: 14px;
      color: var(--text-color);
    }
  }

  .no-result {
    padding: 5px 12px;
  }

  .autoComplete_wrapper {
    width: 100%;

    & > ul {
      bottom: 107%;
      top: auto;

      li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }

  .sent-by {
    display: none;
    position: absolute;
    background: rgba(95, 95, 95, 0.8);
    color: white;
    font-size: 10px;
    padding: 3px 7px;
    left: 25px;
    top: -30px;
    z-index: 10;
    border-radius: 5px;
  }

  .message:not(.incoming) .sent-by {
    right: 10px;
    left: auto;
  }

  .message-body-wrap {
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-left: 10%;
    padding-right: 0;
  }

  .other-listing-select-wrap {
    position: relative;
    .ld-ring {
      position: absolute;
      right: 15px;
      top: 17px;
    }
  }

  .glyphicon.refresh-chat {
    background: var(--body-color);
    padding: 11px;
    color: var(--text-color);
    border-radius: 5px;
    font-size: 12px;
    position: relative;
    top: 0px;
    vertical-align: top;
    height: 34px;
    cursor: pointer;
    width: 34px;
    margin-right: 5px;
  }

  .close-messages {
    #modal-close {
      display: inline-block;
      color: var(--text-color);
      text-align: center;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .send-message {
    position: relative;
    width: 60px;
    height: 34px;
    vertical-align: top;
    .ld-ring {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      div {
        border: 1px solid #fff;
        border-color: #fff transparent transparent transparent;
      }
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .automated-reply {
    font-size: 11px;
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 3px;
    opacity: 0.8;
  }

  .messages-warning {
    color: #7A0F0F;
    background-color: #FFD4D4;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .two-way-messaging-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }

  .message-text {
    word-break: break-word;
  }
}

@media (max-width: 570px) {
  .two-way-messaging-wrap {
    width: 95%;
    top: 15px;

    .message-body-wrap {
      padding-left: 0;
      padding-right: 0;
    }

    .message .message-body {
      max-width: 100%;
    }

    .messages-header {
      .lead-info {
        margin-bottom: 10px;
        flex-direction: column;
      }
      .messages-header-title {
        display: block;
      }
    }
  }
}
