.sm-tabs {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
  li {
    font-weight: 500;
    font-size: 13px;
    width: 50px;
    text-align: center;
    border: 1px solid #ddd;
    overflow: hidden;

    a {
      text-decoration: none;
      color: #5E6469;
      padding: 1px 0;
      width: 100%;
      display: inline-block;
      
      &.active {
        background: #f4f4f4;
        color: #222;
      }
    }
    &:first-child {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-right: none;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.sm-tabs-hidden {
  display: none;
}